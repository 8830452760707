<ion-content class="con-modal__content">

  <div class="con-modal__close-btn">
    <ion-icon name="close-circle" color="primary" (click)="dismissModal()"></ion-icon>
  </div>

  <h2>
    <ion-icon class="con-modal__info-btn" name="ios-information-circle" color="tertiary"></ion-icon>
    Kontakt</h2>

    <pre>
      Kontaktformular/Schreiben Sie uns:

Vorname*:

Name*:

E-Mail*:

Unternehmen/Organisation:

Telefon:

_ Bitte fügen Sie diese Stadt zu CONCILI hinzu: _______

_ Nachricht: ___________

_ Bitte informieren Sie mich über Neuigkeiten zu CONCILI – bspw. neu durchsuchbare Städte.
    </pre>

</ion-content>
