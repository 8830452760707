import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if ('serviceWorker' in navigator && environment.serviceWorker) {
      navigator.serviceWorker
        .register('ngsw-worker.js')
        .then((reg) => console.log('serviceWorker Registrierung erfolgreich. Scope ist ' + reg.scope))
        .catch((error) => console.error(`Registrierung fehlgeschlagen: ${error}`));
    }
  })
  .catch((err) => console.log(err));
