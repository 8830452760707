import { Component, HostListener } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';

import { Platform } from '@ionic/angular';
import { Plugins, StatusBarStyle } from '@capacitor/core';

import { filter } from 'rxjs/operators';

import { environment } from '@env/environment';
import { AuthService } from '@services/auth/auth.service';
import { UpdateService } from '@services/update.service';
import { ScreensizeService } from '@services/screensize.service';

import { User } from '@interfaces/user';

// icons
import { faUserPlus, faTools, faCogs, faList, faSignOut, faFolders, faPaste } from '@fortawesome/pro-light-svg-icons';
import { faUsers, faFolders as faFoldersSolid } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  public appPagesMenu = []; //this.appPagesPremium;

  public isUserSignedIn = false;
  public activePath = '/login';
  public isTablet = false;
  public updateAvailable = false;

  deferredPrompt: any;
  public showPWAButton = false;

  env = environment.production ? 'PROD' : 'DEV';

  constructor(
    private platform: Platform,
    private router: Router,
    private screensizeService: ScreensizeService,
    private swUpdate: UpdateService,
    public auth: AuthService
  ) {
    this.initializeApp();

    this.router.events.pipe(filter((e): e is RouterEvent => e instanceof RouterEvent)).subscribe((e: RouterEvent) => {
      this.activePath = e.url;
    });

    this.auth.user$.subscribe((user: User) => {
      if (user) {
        this.isUserSignedIn = !!(user && (user.roles.editor || user.roles.admin));
        this.menuPages(user);
      }
      console.warn('app auth user', user);
    });

    this.screensizeService.isTabletView().subscribe((isTablet) => {
      this.isTablet = isTablet;
    });

    this.swUpdate.checkAvailableUpdate.subscribe((chkUpd) => {
      console.warn('app: SW Update available!?', chkUpd, performance.now());
      this.updateAvailable = chkUpd;
    });
  }

  async initializeApp() {
    const { SplashScreen, StatusBar } = Plugins;
    try {
      await SplashScreen.hide();
      await StatusBar.setStyle({ style: StatusBarStyle.Light });
    } catch (err) {
      console.log('This is normal in a browser', err);
    }
  }

  showSideMenu() {
    //console.warn('showSideMenu', this.showSideMenu, this.isTablet, this.activePath);

    // show fixed pane when desktop and premium
    if (!this.activePath.startsWith('/cockpit')) return false;
    if (this.isUserSignedIn) {
      if (!this.isTablet) return false;
      return true;
    }
    return false;
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    console.log('app.component - onbeforeinstallprompt', e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showPWAButton = true;
  }

  addToHomeScreen() {
    console.log('App.component - addToHomeScreen');
    if (!this.deferredPrompt) {
      console.warn('no deferredPrompt -> return!');
      return;
    }

    // hide our user interface that shows our A2HS button
    this.showPWAButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      this.deferredPrompt = null;
    });
  }

  menuPages(user: User) {
    const appPagesAdmin = [
      { title: 'User', url: '/cockpit/user', icon: faUsers }, //0
      { title: 'User einladen', url: '/cockpit/user-invite', icon: faUserPlus },
      { title: 'Scraper-Cockpit', url: '/cockpit/scraper-cockpit', icon: faCogs },
      { title: 'Scraper-Tools', url: '/cockpit/scraper-tools', icon: faTools },
      { title: 'Scraper Logs', url: '/cockpit/scraper-logs', icon: faList },
      { title: 'Last-Scraped', url: '/cockpit/scraper-last', icon: faFolders }, //5
      { title: 'Last Paper', url: '/cockpit/scraper-last-body', icon: faFoldersSolid }, //6
      { title: 'Last Files', url: '/cockpit/scraper-last-files', icon: faPaste }, //7
    ];

    this.appPagesMenu = [];

    console.log('menuPages', user.roles);

    if (user.roles?.admin == true) {
      this.appPagesMenu = appPagesAdmin;
      // no scraper cockpit on PROD!
      if (this.env == 'PROD') this.appPagesMenu.splice(2, 1);
    } else if (user.roles?.editor == true) {
      console.log('editor', user.roles?.editor);
      if (user.roles?.editorUser == true)
        this.appPagesMenu.push({ title: 'User', url: '/cockpit/user', icon: faUsers });
      if (user.roles?.editorScraper == true) {
        console.log('editorScraper', user.roles?.editorScraper, this.env, appPagesAdmin[2]);
        if (this.env == 'DEV') this.appPagesMenu.push(appPagesAdmin[2]);
        this.appPagesMenu.push(...appPagesAdmin.splice(6, 7));
      }
    }

    this.appPagesMenu.push({ title: 'Logout', url: '/cockpit/logout', icon: faSignOut });

    console.warn('menuPages', this.appPagesMenu);
  }
}
