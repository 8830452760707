import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ModalFreeLoginHintPage } from './modal-free-login-hint.page';

const routes: Routes = [
  {
    path: '',
    component: ModalFreeLoginHintPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModalFreeLoginHintPageRoutingModule {}
