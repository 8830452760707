import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@services/auth/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./public/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'login-error',
    loadChildren: () => import('./public/login-error/login-error.module').then((m) => m.LoginErrorPageModule),
  },

  {
    path: 'version',
    loadChildren: () => import('./public/version/version.module').then((m) => m.VersionPageModule),
  },

  {
    path: 'cockpit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./cockpit/cockpit-routing.module').then((m) => m.PremiumRoutingModule),
  },

  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
