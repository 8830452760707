import { MaterialModule } from "./../../material.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { ModalFreeLoginHintPageRoutingModule } from "./modal-free-login-hint-routing.module";

import { ModalFreeLoginHintPage } from "./modal-free-login-hint.page";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModalFreeLoginHintPageRoutingModule,
    MaterialModule
  ],
  declarations: [ModalFreeLoginHintPage]
})
export class ModalFreeLoginHintPageModule {}
