import { Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { Component } from "@angular/core";

@Component({
  selector: "app-modal-free-login-hint",
  templateUrl: "./modal-free-login-hint.page.html",
  styleUrls: ["./modal-free-login-hint.page.scss"]
})
export class ModalFreeLoginHintPage {
  constructor(
    private modalController: ModalController,
    public router: Router
  ) {}

  async dismissModal() {
    this.modalController.dismiss();
  }

  async goToLogin() {
    this.dismissModal();
    this.router.navigateByUrl("/login");
  }
}
