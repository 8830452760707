import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { environment } from '@env/environment';

import { User } from '@interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<User>;

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private router: Router) {
    if (environment.emulator) {
      firebase.auth().useEmulator('http://localhost:9099/');
    }

    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs
            .doc<User>(`userProfile/${user.uid}`)
            .valueChanges()
            .pipe(
              map((u) => {
                if (u) {
                  u.uid = user.uid;
                  u.lastSignInTime = user.metadata.lastSignInTime;
                  u.creationTime = user.metadata.creationTime;
                  if (!u.fcmTokens) u.fcmTokens = [];
                  if (!u.bookedBodiesIds) u.bookedBodiesIds = [];
                } else console.warn('afAuth AUTHSTATE afs.MAP NO u', user.uid, user);
                return u;
              })
            );
        } else {
          return of(null);
        }
      })
    );
  }

  loginUser(email: string, password: string): Promise<any> {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  resetPassword(email: string): Promise<void> {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  logoutUser(): Promise<void> {
    return firebase.auth().signOut();
  }

  reloadUser() {
    const user = firebase.auth().currentUser;
    user.reload();
  }

  // Store user data in DB
  storeUserData(user: {
    uid: string;
    firstName: string;
    lastName: string;
    organization: string;
    title: string;
    street: string;
    zip: string;
    city: string;
  }) {
    if (user.uid) {
      const userRef: firebase.firestore.DocumentReference<any> = firebase.firestore().doc(`userProfile/${user.uid}`);
      return userRef.set(user, { merge: true });
    } else return Promise.reject(new Error('no uid'));
  }

  async CheckUserVerificationCode(email: string, code: string) {
    let checkCode = false;
    let userId = null;
    await firebase
      .firestore()
      .collection('userProfile')
      .where('email', '==', email)
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.size == 1) {
          querySnapshot.forEach(async (userSnap) => {
            if (userSnap.data().random == code) {
              checkCode = true;
              userId = userSnap.id;
              // set DB to verified!
              userSnap.ref
                .set({ emailVerified: true, randomVerified: code, verified_at: new Date() }, { merge: true })
                .catch((error) => console.error('userSNAP.ref.set ERROR', error));
            }
          });
        } else {
          throw new Error('no user found');
        }
      });

    return { checkCode, userId };
  }

  ///// Role-based Authorization //////

  canRead(user: User): boolean {
    const allowed = ['admin', 'editor', 'premium'];
    return this.checkAuthorization(user, allowed);
  }
  canEdit(user: User): boolean {
    const allowed = ['admin', 'editor'];
    return this.checkAuthorization(user, allowed);
  }
  canDelete(user: User): boolean {
    const allowed = ['admin'];
    return this.checkAuthorization(user, allowed);
  }

  // determines if user has matching role
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) return false;
    for (const role of allowedRoles) {
      if (user.roles && user.roles[role]) {
        return true;
      }
    }
    return false;
  }
}
