<ion-split-pane [when]="showSideMenu()"  contentId="conMain" class="con-app__splitpane">
  <!--  the side menu  -->
  <ion-menu menuId="conMenu" contentId="conMain" >
    

    <ion-content class="con-app__menu" [class]="env=='DEV'? 'dev-cockpit':''">
      <div class="con-app__menu-logo" [routerDirection]="'root'" routerLink="/cockpit/dashboard">
        <img alt="Concili" src="/assets/img/concili-logo-180px@2x.png" class="con-app__logo-img">
      </div>

      <h3 class="ion-text-center">{{env}}-Cockpit</h3>

      <ion-list class="con-app__menu-list" lines="none">
        <ion-menu-toggle auto-hide="false" *ngFor="let p of appPagesMenu">

          <ion-item class="con-app__menu-item" button [routerDirection]="'root'" [routerLink]="[p.url]" routerLinkActive="active-link">
            <span class="con-app__menu-icon"><fa-icon [icon]="p.icon"></fa-icon></span>
            <ion-label>
              {{ p.title }}
            </ion-label>
          </ion-item>

        </ion-menu-toggle>
      </ion-list>

      
      <div class="con-app__menu-bottom" slot="fixed">
        <p class="ion-text-center" *ngIf="showPWAButton">
          <ion-button (click)="addToHomeScreen()" color="light">
            <fa-icon [icon]="['fal','cloud-download']" size="lg" class="fa-btn-icon"></fa-icon>
            APP installieren
          </ion-button>
        </p>
        <p class="ion-text-center" *ngIf="!showPWAButton && updateAvailable">
          <a routerLink="/cockpit/update">
            <ion-text color="primary"><strong>Update verfügbar!</strong></ion-text>
          </a>
        </p>
        <ion-menu-toggle auto-hide="false" >
          <p class="ion-text-center">
            <a routerLink="/cockpit/impressum">Impressum</a>
          </p>
        </ion-menu-toggle>
      </div>


    </ion-content>

    
  </ion-menu>

  <!-- the main content -->
  <ion-router-outlet id="conMain"></ion-router-outlet>
</ion-split-pane>