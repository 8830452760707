<ion-content class="con-modal__content">

  <div class="con-modal__close-btn">
    <ion-icon name="close-circle" color="primary" (click)="dismissModal()"></ion-icon>
  </div>

  <h2>
    <ion-icon class="con-modal__info-btn" name="ios-information-circle" color="tertiary"></ion-icon>
    Login erforderlich</h2>
  
  <p>Melden Sie sich bitte an, um das gewünschte Dokument direkt erreichen zu können.</p>

  <div class="con-form__submit-btn">
    <button mat-raised-button class="mat-elevation-z4" color="accent" (click)="goToLogin()" >Jetzt anmelden</button>
  </div>
  
</ion-content>
  